import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import CoursesIntro from "../components/courses-intro/CoursesIntro"
import WhatLearn from "../components/what-learn/WhatLearn"
import HowItWorks from "../components/how-it-works/HowItWorks"
import WhoAccepted from "../components/who-accepted/WhoAccepted"
import WhyCodetrain from "../components/why-codetrain/WhyCodetrain"
import ProgramSummary from "../components/program-summary/ProgramSummary"
import IntakeProcess from "../components/intake-process/IntakeProcess"
import CareerPaths from "../components/career-paths/CareerPaths"

const Courses = () => {
  return (
    <MainLayout>
      <Title>
        Codetrain Africa | Courses - Fullstack web and mobile app development
      </Title>
      <Meta
        name="description"
        content="Learn fullstack web and mobile app development. Codetrain's 
        curriculum is designed based on the skills that are in high demand by 
        most of the top companies. Our courses focus on practical skills and 
        includes interactive learning activities that makes it easy for our 
        students to build real world projects in no time."
      />
      <CoursesIntro />
      <WhatLearn />
      <CareerPaths />
      <HowItWorks />
      <WhoAccepted />
      <WhyCodetrain />
      <ProgramSummary />
      <IntakeProcess />
    </MainLayout>
  )
}

export default Courses
