import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./whatLearn.scss"

const WhatLearn = () => (
  <StaticQuery
    query={graphql`
      query {
        web: file(relativePath: { eq: "img/elements/web-course.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container courses-web">
          <div className="row courses-web__content">
            <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-5 order-lg-7">
                  <div className="courses-web__text">
                    <h2>What you’ll learn</h2>
                    <br />
                    <p>
                      At Codetrain, we run a 2-year program. By the end of this
                      program, you will go from novice to mid-level software
                      developer.
                    </p>
                    <p>
                      The first year has a focus on training in software
                      development. While the second year narrows down on career
                      services that are aimed at helping you launch your career
                      in the best way. It consists of interview prep, technical
                      interview workshops, an innovation challenge, and
                      internships.
                    </p>

                    <h5>Year 1</h5>
                    <p>
                      The first year is when most of the training happens.
                      Candidates learn how to code from scratch and begin to
                      build their portfolios right from month one.
                    </p>

                    <h5>Year 2</h5>
                    <p>
                      The second year focuses on career services. Trainees have
                      internships, mock interviews, and a mega Demo Day to
                      prepare them to officially graduate and land their first
                      full-time roles.
                    </p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <Img
                    fluid={data.web.childImageSharp.fluid}
                    alt="codetrain developer"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default WhatLearn
