import { Link } from "gatsby"
import React from "react"
import "./intakeProcess.scss"

const IntakeProcess = () => {
  return (
    <div className="section-container how-to-apply-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="main-section-heading intake-title">
              <h3 className="text-center">Apply in 3 simple steps</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <h4 className="stage-number stage-number1">Step 1</h4>
            <div className="option-box option-box1">
              <h5>Fill the Enroll now form</h5>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <h4 className="stage-number stage-number2">Step 2</h4>
            <div className="option-box option-box2">
              <h5>Complete application</h5>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <h4 className="stage-number stage-number3">Step 3</h4>
            <div className="option-box option-box3">
              <h5>Start your codetrain journey</h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <p>
              Successful applicants go ahead to make their deposits and get
              ready for the kickoff event.
            </p>
            <p>
              For a more in-depth breakdown of our program, you can view the{" "}
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1zXWhhJXynYfSW79tNeI6JjCXop9AavXq/view?usp=share_link"
              >
                Codetrain Africa Catalog
              </a>
              .
            </p>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <p className="text-center">
              <Link
                to="https://app.codetrain.africa/apply"
                className="btn-custom-primary-normal"
              >
                Enroll for Software Engineering
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntakeProcess
