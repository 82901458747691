import React from "react"
import "./programSummary.scss"

const ProgramSummary = () => {
  return (
    <div className="section-container course-and-careers-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-section-heading">
              <h3 className="program-summary-title text-center">
                Program summary
              </h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <table className="table">
              <tr>
                <th>Duration</th>
                <td>2 years</td>
              </tr>

              <tr>
                <th>Hours of commitment per week</th>
                <td>25</td>
              </tr>

              <tr>
                <th>GH₵</th>
                <td>38,500</td>
              </tr>

              <tr>
                <th>Medium</th>
                <td>In-person or virtual</td>
              </tr>

              <tr>
                <th>Start dates</th>
                <td>February, May, October</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgramSummary
