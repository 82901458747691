import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import "./coursesIntro.scss"

const CoursesIntro = () => (
  <StaticQuery
    query={graphql`
      query {
        winimie: file(
          relativePath: { eq: "img/elements/student profile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        engineer: file(
          relativePath: { eq: "img/elements/software_engineer_lvl5.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adwoa: file(
          relativePath: { eq: "img/elements/student profile-adwoa.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container courses-intro">
          <div className="container">
            <div className="main-section-heading">
              <h3 className="text-center">
                Want to get started in the world of coding and software
                engineering as a career?
              </h3>
              <p>The 2-year Codetrain experience will help you do just that.</p>
              <p>
                The program is built by the teaching fellows at Codetrain in
                consultation some of the best tech professionals in the world.
                We worked with FAANG engineers and talent professionals from
                several partner companies including Bloomberg Tech, I&P
                Education to Employment program, Giraffe Consult, and mPharma to
                make sure our program is world class.
              </p>
              <p>
                In this program, you’ll learn:
                <ul>
                  <li>How to build interactive websites and web apps</li>
                  <li>Data structures and algorithms</li>
                  <li>Build cross-platform mobile applications</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
              <div className="row">
                <div class="col-md-3">
                  <Img
                    fluid={data.winimie.childImageSharp.fluid}
                    className="img-fluid"
                    alt="codetrain developer"
                  />
                </div>
                <div class="col-md-6 d-none d-sm-block">
                  <div class="">
                    <Img
                      fluid={data.engineer.childImageSharp.fluid}
                      className="img-fluid"
                      alt="codetrain developer"
                    />
                  </div>
                </div>
                <div class="col-md-3 d-none d-sm-block">
                  <Img
                    fluid={data.adwoa.childImageSharp.fluid}
                    className="img-fluid"
                    alt="codetrain developer"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <p className="text-center">
            <Link
              to="https://app.codetrain.africa/apply"
              className="btn-custom-primary-normal"
            >
              Enroll for Software Engineering
            </Link>
          </p>
        </div>
      )
    }}
  />
)

export default CoursesIntro
