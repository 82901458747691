import React from "react"
import "./whyCodetrain.scss"

const WhyCodetrain = () => {
  return (
    <div className="section-container course-and-careers-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-section-heading">
              <h3 className="why-codetrain-title text-center">
                Why Codetrain?
              </h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <p>
              Every part of the Codetrain experience is designed to see you
              succeed. The training you receive is built on pillars that
              guarantee your professional success.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h5 className="custom-blue-text">Hands-on learning</h5>
            <p>
              Project-based learning is our way. At code train, you learn by
              doing. From your first day to your last, you’re graded on actual
              work that is similar to what you’ll be doing on the job. If it has
              no application in the industry, we don’t bother with it.
            </p>
          </div>

          <div className="col-md-6 col-sm-12">
            <h5 className="custom-blue-text">Community</h5>
            <p>
              Your teaching fellows are actively rooting for you. Whether you
              need answers, clarification or encouragement, you’ll find it with
              them.
            </p>

            <p>
              Our close-knit trainee communities ensure that throughout your
              years of training, you never feel helpless and alone. The
              peer-to-peer mentoring and support is unmatched.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h5 className="custom-blue-text">Career services</h5>
            <p>
              As part of your training, you are prepared for job opportunities.
              You get to experience mock interviews and practice technical tests
              that prepare you to ace the toughest recruitment processes. We do
              everything we can to ensure that you secure your first full-time
              role as soon as you’re ready.
            </p>
          </div>

          <div className="col-md-6 col-sm-12">
            <h5 className="custom-blue-text">Flexible learning</h5>
            <p>
              Our program is designed for learners from anywhere in the world.
              In-person or virtual? It’s up to you to choose.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h5 className="custom-blue-text">All-round learning</h5>
            <p>
              Beyond technology, you get a holistic education where you learn to
              communicate and advocate for yourself. You are also trained to
              become an ethical leader with the audacity to dream big and do
              hard things.
            </p>
          </div>

          <div className="col-md-6 col-sm-12">
            <h5 className="custom-blue-text">Codetrain partners</h5>
            <p>
              We have a relationship with some of the best relationships and
              start ups in the country. Because of this, our fingers are always
              on the pulse. They tell us what they need in an ideal candidate.
              We build it into the program. Our students are prepared for work.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h5 className="custom-blue-text">Mentorship</h5>
            <p>
              Beyond the amazing teaching fellows, you get to connect with
              mentors working in top tech companies like Spotify, Google,
              LinkedIn and  Netflix.
            </p>

            <p>
              You also get support from our close-knit alumni network. Which
              consists of more established software developers working for local
              and international companies.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyCodetrain
