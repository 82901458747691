import React from "react"
import "./careerPaths.scss"

const CareerPaths = () => {
  return (
    <div className="section-container course-and-careers-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-section-heading">
              <h3 className="career-paths-title text-center">Career paths</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <p>Our graduates are a good fit for roles such as</p>
            <ul>
              <li>Mobile apps developer</li>
              <li>Frontend developer</li>
              <li>Backend developer</li>
              <li>Fullstack developer</li>
              <li>DevOps engineer</li>
              <li>Data scientist</li>
              <li>Automated Testing Engineer</li>
            </ul>
          </div>

          <div className="col-lg-6 col-sm-12">
            <p>You can also venture into non-coding roles such as</p>
            <ul>
              <li>Product manager</li>
              <li>Business analyst</li>
              <li>Sales engineer</li>
              <li>Developer advocate</li>
              <li>Technical recruiter</li>
              <li>Manual Tester - QA</li>
              <li>UI/UX Designer</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerPaths
