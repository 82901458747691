import React from "react"
import "./whoAccepted.scss"

const WhoAccepted = () => {
  return (
    <div className="section-container demo-day-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-section-heading">
              <h2 className="text-center">Who gets accepted</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <p>What you don’t need</p>
            <ul>
              <li>Computer science degree</li>
              <li>Experience in tech</li>
              <li>A degree</li>
            </ul>
          </div>

          <div className="col-lg-6 col-sm-12">
            <p>But you need to:</p>
            <ul>
              <li>Be computer literate</li>
              <li>Be teachable</li>
              <li>Be willing to put in the work</li>
              <li>Pass our intake assessment</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhoAccepted
