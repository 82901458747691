import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import "./howItWorks.scss"

const HowItWorks = () => (
  <StaticQuery
    query={graphql`
      query {
        mobile: file(relativePath: { eq: "img/elements/mobile-course.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container courses-mobile">
          <div className="row courses-mobile__content">
            <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-5">
                  <div className="courses-mobile__text">
                    <h2>How it works</h2>
                    <br />
                    <p>
                      You get access to course content and are expected to study
                      it. You will receive instructions on what to exercises to
                      do. Once every week, there is a class to go over the
                      course content that was made available. You get to choose
                      when your live class happens. Here are the options:
                    </p>
                    <ul>
                      <li>Weekday live class</li>
                      <li>Weekend live class</li>
                      <li>Virtual live class</li>
                    </ul>
                    <p>
                      You should be able to commit about 25-30 hours a week to
                      study and practice.
                    </p>
                    {/* <p>
                      <Link to="/program" className="btn-custom-primary-normal">
                        Enroll now
                      </Link>
                    </p> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <Img
                    fluid={data.mobile.childImageSharp.fluid}
                    alt="codetrain internships"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default HowItWorks
